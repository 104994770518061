import React from 'react'
import {graphql} from 'gatsby'
import SEO from '../components/seo'
import {PageProps} from 'gatsby'

const Terms: React.FC = ({data}: any) => {
  const {markdownRemark} = data
  const {html} = markdownRemark
  return (
    <div className="term container max-w-screen-md mx-auto px-8 lg:flex lg:items-center mt-32">
      <SEO title="이용 약관" description="에잇코즈의 이용 약관입니다."/>
      <div dangerouslySetInnerHTML={{__html: html}}/>
    </div>
  )
}

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: {slug: {eq: "/terms"}}) {
      html
      #      frontmatter {
      #        date(formatString: "MMMM DD, YYYY")
      #        slug
      #        title
      #      }
    }
  }
`

export default Terms
